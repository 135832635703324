import { apiUrl, axios } from '@/api/api.js';
import { PAGE_SIZE } from '@/constants/common';
import { generateErrors } from '@/main/utils/common';

export const getAllRegisters = async (page = 1, pageSize = PAGE_SIZE, filter = {}) => {
    try {
        const result = await axios.get(`${apiUrl}/register`, {
            params: {
                'page-size': pageSize,
                page,
                filter: JSON.stringify(filter),
                include: 'image'
            }
        });

        return {
            registers: [...result.data.data],
            included: result.data.included,
            meta: result.data.meta
        }
    } catch (error) {
        generateErrors(error);
    }
};

export const getRegisterById = async id => {
  try {
    const result = await axios.get(`${apiUrl}/register/${id}`);

    return {
      data: result.data.data,
      included: [],
    }
  } catch (error) {
      generateErrors(error);
  }
};

export const addRegister = async data => {
    try {
        const response = await axios.post(`${apiUrl}/register`, data);
        return response;
    } catch (error) {
        generateErrors(error);
    }
};

export const patchRegister = async (id, data) => {
  try {
    const response = await axios.patch(`${apiUrl}/register/${id}`, data);

    return response;
  } catch (error) {
      generateErrors(error);
  }
};

export const deleteRegister = async id => {
  try {
    const response = await axios.delete(`${apiUrl}/register/${id}`);

    return response;
  } catch (error) {
      generateErrors(error);
  }
}
